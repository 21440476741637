import {Spinner} from "@blueprintjs/core";
import React from "react";
import Messaging from "./Messaging";

function CheckingState({headingText = 'Checking Order Status...'}) {
  return (
      <Messaging
          icon={<Spinner size={58}/>}
          headingText={headingText}
          subheading={null}
          supportLink={null}
      />
  )
}


export default CheckingState
