const apiUrl = process.env.REACT_APP_API_ENDPOINT

const Service = {
  async checkOrderStatus(id) {
    try {
      const result = await fetch(`${apiUrl}/get_invitation_details?invitationId=${id}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'default'
      })
      return result.json()
    } catch(e) {
      return {}
    }
  },

  async submitOrderInfo(id, payload) {
    try {
      const result = await fetch(`${apiUrl}/invitation_response`, {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          Object.assign(payload, {invitationId: id})
        )
      })
      return result.json()
    } catch(e) {
      return false
    }
  },

  async omitInvitation(invitation) {
    const toSubmit = {
      orderNumber: invitation.orderNumber,
      orderBusinessUnitId: invitation.orderBusinessUnitId,
      responseState: 'OMITTED',
      responseDetails: null
    }
    return await Service.submitOrderInfo(invitation.invitationId, toSubmit)
  }
}


export default Service
