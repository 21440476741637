import {Icon, Intent} from "@blueprintjs/core";
import React from "react";

function Messaging(props) {
  const {
    className = '',
    icon = <Icon icon="tick-circle" intent={Intent.SUCCESS} />,
    headingText = 'Thank you for submitting the information',
    subheading = <span>As a next step, an account will be created and the registered administrator will be notified shortly</span>,
    supportLink = (
      <strong>
        If you have any questions meanwhile, contact
        <a href="https://support.dell.com">&nbsp;Support</a>
      </strong>
    )
  } = props
  return (
      <section className={`messaging-wrap ${className}`}>
        {icon}
        {headingText && <h3>{headingText}</h3>}
        {subheading && <h4>{subheading}</h4>}
        {supportLink && supportLink}
      </section>
  )
}

export default Messaging
