import React from "react";
import Messaging from "./Messaging";

function ConfirmationState({
    subheadingText = 'As a next step, an account will be created and the registered administrator will be notified shortly'
                           }) {
  return (
      <Messaging
          headingText="Thank you for submitting the information"
          subheading={<span>{subheadingText}</span>}
      />
  )
}

export default ConfirmationState
