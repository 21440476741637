export default {
  getQueryParamsMap(qs = window.location.search) {
    return (qs || '')
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, keyValue) => {
          const [key, value] = keyValue.split('=')
          acc[key] = value || ''
          return acc
        }, {})
  }
}
