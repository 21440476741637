import {
  Button,
  Classes,
  ControlGroup,
  FormGroup,
  H5, Icon,
  InputGroup,
  Intent, Label,
  MenuItem,
  Overlay, Position,
  Spinner, Tooltip
} from "@blueprintjs/core";
import React, {useContext, useState, useEffect} from "react";
import {MultiSelect} from "@blueprintjs/select";
import Service from "../Service";
import AppContext from "../AppContext";

const RegionsMultiSelect = MultiSelect.ofType();

function makeID(name) {
  return name.replace(/\s/g, '-').replace(/\W/g, '').toLowerCase()
}

function _get(source, path) {
  return path.split('.').reduce((target, each) => {
    return target[each]
  }, source)
}

function FieldLabelWithInfo({label, tooltip = [
  'Select a storage region that best suits you business requirements.',
  'This is the region where the backed up data will be stored securely.'
]}) {
  return (
    <ControlGroup vertical={false} className="field-label-with-info">
      <Label>{label}</Label>
      {tooltip && (
        <Tooltip content={
          <div>
            {tooltip.map(each => <div>{each}</div>)}
          </div>
        } position={Position.TOP}>
          <Icon icon="info-sign" />
        </Tooltip>
      )}
    </ControlGroup>
  )
}

function CreditsDistribution({listName, types, credits, disabled = false, onChange = ()=> {}, showMode = 'remaining'}) {
  const [consumptions, setConsumptions] = useState({})
  const [maxConsumptions, setMaxConsumptions] = useState(0)
  const [totalConsumed, setTotalConsumed] = useState(0)
  const [remainingConsumed, setRemainingConsumed] = useState(0)

  useEffect(() => {
    const total = Object.values(consumptions).reduce((total, each) => total + each, 0)
    setTotalConsumed(total)
    setRemainingConsumed(credits - total)
  }, [consumptions])

  useEffect(() => {
    onChange({
      listName,
      consumptions,
      totalConsumed
    })
  }, [totalConsumed])

  useEffect(() => {
    setConsumptions(types.reduce((acc, t) => {
      acc[t] = 0
      return acc
    }, {}))
  }, [])

  return listName && types?.length && credits > 0 && (
    <section className="credits-distribution-wrap">
      <div className="bp3-callout">
        Thank you for your purchase that includes <strong>{credits}</strong> user licenses for SaaS Apps.
        <br/>
        Please tell us how you would like to utilize these user licenses.
      </div>
      <div className="apps-list">
        {types.map((each, key) => (
            <FormGroup
                key={key}
                helperText=""
                label={`${each}`}
                labelFor={`${makeID(each)}-licenses`}
            >
              <InputGroup id={makeID(each)} value={''}
                          disabled={disabled}
                          onChange={(e) => {
                            setConsumptions({
                              ...consumptions,
                              [each]: Number(e.target.value)
                            })
                          }}
                          value={consumptions[each]}
                          placeholder="" />
            </FormGroup>
        ))}
      </div>
      {
        showMode === 'remaining' && remainingConsumed !== 0 ?
          <small className="danger-text">
            Remaining Licenses <strong>{remainingConsumed}</strong>.
            <br/>Utilized licenses do not equal the total purchased.
          </small> :
        showMode === 'total' && remainingConsumed !== 0 ?
          <small className="danger-text">
            Total Licenses <strong>{totalConsumed}</strong>.
            <br/>Utilized licenses do not equal the total purchased.
          </small> : null
      }

    </section>
  )
}

function OpenState(props) {
  const appCtx = useContext(AppContext)

  const [submitting, setSubmitting] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)

  const invitation = props?.invitation
  const regionTypes = invitation?.products
  const saasLicenses = invitation?.saasLicenses
  const publicDomains = invitation?.publicDomains || []

  const formFields = [
    'firstName:First Name:required',
    'lastName:Last Name:required',
    'adminEmail:Email Address:required,email,noPublicEmailDomains',
    ...regionTypes.map(t => `regions.${t}:${t}:required`),
    ...Object.keys(saasLicenses)
        .filter(t => saasLicenses[t].credits > 0)
        .map(t => `productDistribution.${t}:${t}:required,creditsAccountedFor`)
  ]
  const Validators = {
    required: {
      message() { return `Please enter some information in the field.` },
      validate(val) {
        if (val instanceof Array) {
          return !!val.length
        } else if (typeof val === 'object') {
          return !!Object.keys(val).length
        } else if (val && val.trim) {
          return !!val.trim().length
        } else {
          return !!val
        }
      }
    },
    chooseOne: {
      message() { return `Please choose one or more from the list.` },
      validate(val) {
        if (val instanceof Array) {
          return !!val.length
        } else if (typeof val === 'object') {
          return !!Object.keys(val).length
        } else if (val && val.trim) {
          return !!val.trim().length
        } else {
          return !!val
        }
      }
    },
    email: {
      message() { return `Please enter a valid email address.` },
      validate(val) { return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(val) },
    },
    noPublicEmailDomains: {
      message() { return `Please enter a work email address - not from public email domains.` },
      validate(val) {
        return publicDomains.filter(d => {
          return new RegExp(`\\@${d}$`).test(val)
        }).length === 0
      }
    },
    creditsAccountedFor: {
      message() { return false },
      validate(val, fieldName) {
        const data = saasLicenses[fieldName.split('.').pop()]
        if (Object.keys(val).length !== data.types.length) {
          return false
        }
        const totalConsumption = Object.values(val).reduce((total, each) => total + each, 0)
        if (totalConsumption !== data.credits) {
          return false
        }
        return true
      }
    }
  }

  const [formData, setFormData] = useState({
    productDistribution: Object.keys(saasLicenses).reduce((acc, each) => {
      acc[each] = {}
      return acc
    }, {}),
    regions: regionTypes.reduce((acc, each) => {
      acc[each] = []
      return acc
    }, {})
  })

  const [dirtyState, setDirtyState] = useState({})
  const [validState, setValidState] = useState({})

  useEffect(() => {
    setValidState({})
    validateForm()
  }, [formData])

  function validateForm() {
    let isValid, messaging = {}
    try {
      const r = formFields.map(fld => {
        const [fieldName, fieldLabel, validators] = fld.split(':')
        if (validators.length) {
          const vals = validators.split(',')
          return vals.reduce((acc, vName) => {
            const validator = Validators[vName]
            const result = validator.validate(_get(formData, fieldName), fieldName, formData)
            if (result) {
              acc++
              if (!messaging[fieldName]) {
                messaging = {
                  ...messaging,
                  [fieldName]: null
                }
              }
            } else if (!messaging[fieldName] && validator.message()) {
              messaging = {
                ...messaging,
                [fieldName]: validator.message()
              }
            }
            return acc
          }, 0) === vals.length
        }
        return true
      })
      isValid = r.filter(valid => !!valid).length === formFields.length
    } catch(e) {
      isValid = false
    }

    setValidState(messaging)
    setCanSubmit(isValid)

    return isValid
  }

  function handleCreditsDistributionChange({listName, consumptions, totalConsumed}) {
    if (listName && consumptions) {
      setFormData({
        ...formData,
        productDistribution: {
          ...formData.productDistribution,
          [listName]: consumptions
        }
      })
    }
  }

  function renderProductDetails(data, listName, key) {
    const saasLicenses = data?.saasLicenses[listName]
    return (
      <>
        {saasLicenses && (
            <CreditsDistribution
                listName={listName}
                onChange={handleCreditsDistributionChange}
                {...saasLicenses}
            />
        )}
        <FormGroup
            key={key}
            helperText=""
            label={<FieldLabelWithInfo label={`${listName} Storage Region`} />}
            labelFor={`${listName}-region`}
        >
          <RegionsMultiSelect
              fill={true} minimal={true}
              items={data.productRegions[listName]}
              itemRenderer={(item, options) => renderMultiSelectMenuItem(item, options, listName)}
              noResults={<MenuItem disabled={true} text="No regions found" />}
              selectedItems={formData.regions[listName]}
              onItemSelect={(item) => handleAddMultiSelectItem(item, listName)}
              popoverProps={{minimal: true}}
              tagRenderer={(tag) => renderMultiSelectTag(tag)}
              tagInputProps={{
                disabled: submitting,
                inputProps: {
                  readOnly: true,
                  onBlur: () => {
                    setDirtyState({...dirtyState, [`regions.${listName}`]: true})
                  },
                },
                onRemove: (item, index) => handleRemoveMultiSelectItem(index, listName),
                rightElement: formData.regions[listName].length > 0 ?
                    <Button icon="cross" minimal={true} onClick={() => clearMultiSelectList(listName)} /> :
                    undefined,
                tagProps: {minimal: true,},
              }}
              placeholder=""
          />
          {Messaging(`regions.${listName}`)}
        </FormGroup>
      </>
    )
  }
  function renderMultiSelectMenuItem(item, {handleClick}, listName) {
    const isSelected = formData.regions[listName].find(e => e.name === item.name)

    return <MenuItem key={item.name}
                     icon={isSelected ? "tick" : "blank"}
                     text={item.displayName}
                     shouldDismissPopover={false}
                     onClick={handleClick} />
  }
  function renderMultiSelectTag(item) {
    return `${item.displayName}`
  }
  function handleAddMultiSelectItem(item, listName) {
    if(!formData.regions[listName].find(e => e.name === item.name)) {
      setFormData({
        ...formData,
        regions: {
          ...formData.regions,
          [listName]: [
            ...formData.regions[listName],
            item
          ]
        }

      })
    }
  }
  function handleRemoveMultiSelectItem(index, listName) {
    const updated = [
      ...formData.regions[listName].slice(0, index),
      ...formData.regions[listName].slice(index + 1),
    ]
    setFormData({
      ...formData,
      regions: {
        ...formData.regions,
        [listName]: updated
      }
    })
  }
  function clearMultiSelectList(listName) {
    setFormData({
      ...formData,
      regions: {
        ...formData.regions,
        [listName]: []
      }

    })
  }

  async function handleFormSubmit() {
    if (!canSubmit) {
      const dirty = {}
      formFields.forEach(fld => {
        const [fieldName, validators] = fld.split(':')
        dirty[fieldName] = true
      })
      setDirtyState(dirty)
      return
    }


    const productDetails = regionTypes.reduce((acc, product) => {
      const ret = {
        product,
        regions: formData.regions[product].map(region => ({name: region.name}))
      }

      if (formData.productDistribution[product]) {
        ret.productDistribution = Object.keys(formData.productDistribution[product]).map(each => ({
          name: each,
          quantity: formData.productDistribution[product][each]
        }))
      }
      acc.push(ret)
      return acc
    }, [])

    const {
      firstName, lastName, adminEmail,
    } = formData


    const toSubmit = {
      orderNumber: invitation.orderNumber,
      orderBusinessUnitId: invitation.orderBusinessUnitId,
      responseState: 'PROVIDED',
      responseDetails: {
        orderNumber: invitation.orderNumber,
        orderBusinessUnitId: invitation.orderBusinessUnitId,
        firstName, lastName, adminEmail,
        productDetails
      }
    }

    setSubmitting(true)
    const result = await Service.submitOrderInfo(invitation.invitationId, toSubmit)
    setSubmitting(false)

    if (result?.isSuccess) {
      appCtx.setAppState({
        type: 'CONFIRM'
      })
    } else {
      appCtx.setAppState({
        type: 'ERROR',
        invitation: result
      })
    }
  }
  async function handleNotMe() {
    setSubmitting(true)
    const result = await Service.omitInvitation(invitation)
    setSubmitting(false)
    if (result?.isSuccess) {
      appCtx.setAppState({
        type: 'OMITTED',
        invitation: {}
      })
    } else {
      appCtx.setAppState({
        type: 'ERROR',
        invitation: result
      })
    }
  }

  function Messaging(fieldName) {
    return dirtyState[fieldName] && validState[fieldName] && (<small className="danger-text">{validState[fieldName]}</small>)
  }

  return (
      <section className="form-wrap">
        <h1>Let's get started with some basic information and your preferences</h1>
        <form className="bordered">
          <h4>Please provide the following information below</h4>
          <FormGroup
              helperText=""
              label="First Name"
              labelFor="first-name"
          >
            <InputGroup id="first-name" value={formData.firstName}
                        disabled={submitting}
                        onChange={(e) => {setFormData({...formData, firstName: e.target.value })}}
                        onBlur={() => setDirtyState({...dirtyState, firstName: true})}
                        placeholder="" />
            {Messaging('firstName')}
          </FormGroup>
          <FormGroup
              helperText=""
              label="Last Name"
              labelFor="last-name"
          >
            <InputGroup id="last-name" value={formData.lastName}
                        disabled={submitting}
                        onChange={(e) => {setFormData({...formData, lastName: e.target.value })}}
                        onBlur={() => setDirtyState({...dirtyState, lastName: true})}
                        placeholder="" />
            {Messaging('lastName')}
          </FormGroup>
          <FormGroup
              helperText=""
              label={<FieldLabelWithInfo label="Email Address"
                                         tooltip={[
                                          'Enter a valid corporate email address.',
                                          'A public domain email address like gmail.com is not accepted.'
                                        ]} />}
              labelFor="email-address"
          >
            <InputGroup id="email-address" value={formData.adminEmail}
                        disabled={submitting}
                        inputProps={{type: 'email'}}
                        onChange={(e) => {setFormData({...formData, adminEmail: e.target.value })}}
                        onBlur={() => setDirtyState({...dirtyState, adminEmail: true})}
                        placeholder="" />
            {Messaging('adminEmail')}
          </FormGroup>
          {
            regionTypes.map((type, i) =>
              renderProductDetails(props?.invitation, type, i)
            )
          }

          <small className="privacy-message disabled-text">
            <p>
              The requested data is necessary for the performance of the service you’ve ordered to
              <a href="https://i.dell.com/sites/doccontent/legal/terms-conditions/en/Documents/Dell-Technologies-List-for-Privacy-Statement-May-2018.pdf">Dell Technologies</a>.
              The data you’ve provided to us will be processed by Dell Technologies in accordance with our
              <a href="https://www.dell.com/learn/us/en/uscorp1/policies-privacy-country-specific-privacy-policy">Privacy Statement</a>.
            </p>
            <p>
              By clicking “Submit”, you have read our
              <a href="https://www.dell.com/learn/us/en/uscorp1/policies-privacy-country-specific-privacy-policy">Privacy Statement</a>,
              understood your rights and how
              <a href="https://i.dell.com/sites/doccontent/legal/terms-conditions/en/Documents/Dell-Technologies-List-for-Privacy-Statement-May-2018.pdf">Dell Technologies</a> uses and protects your data.
            </p>
          </small>

          <Button fill={true}
                  disabled={!canSubmit || submitting}
                  onClick={handleFormSubmit}
                  intent={Intent.PRIMARY}>
            Submit
          </Button>
          <div className="hr-with-text">
            <hr/>
            <label>OR</label>
          </div>

          <Button fill={true}
                  disabled={submitting}
                  intent={Intent.PRIMARY} outlined={true}
                  onClick={handleNotMe}>I do not have this information</Button>

          <Overlay className={Classes.OVERLAY_SCROLL_CONTAINER}
                   isOpen={submitting} usePortal={false}
                   canEscapeKeyClose={false} canOutsideClickClose={false}>
            <Spinner size={50} />
            <H5>Submitting...</H5>
          </Overlay>
        </form>

        <h6 className="disabled-text">If you have any questions or concerns, please reach out to <a href="http://support.dell.com">support.dell.com</a></h6>
      </section>
  )
}


export default OpenState
