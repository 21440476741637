import React from 'react'

const AppContext = React.createContext({
  appState: {
    type: 'CHECKING',
    invitation: {},
  },
  setAppState: (state) => {},
});

export default AppContext
