import React, {useContext} from "react";
import {Icon, Intent} from "@blueprintjs/core";
import Messaging from "./Messaging";
import AppContext from "../AppContext";

function ErrorState(props) {
  let message = 'An error occurred while processing your request'
  const Context = useContext(AppContext)

  if (Context?.appState?.invitation?.errors) {
    message = Context?.appState.invitation?.errors[0].errorMessage
  } else if (Context.appState.errorMessage) {
    message = Context.appState.errorMessage
  }

  return (
      <Messaging
          icon={(<Icon icon="error" intent={Intent.DANGER} />)}
          headingText={null}
          subheading={<span>{message}</span>}
          supportLink ={
            <strong>
              Please contact
              <a href="https://support.dell.com">&nbsp;Support</a> to get further help
            </strong>
          }
      />
  )
}

export default ErrorState
