import React, {useEffect, useState, useContext} from 'react'

import Utils from './Utils'
import Service from './Service'

import AppContext from './AppContext'

import OpenState from "./views/OpenState";
import CheckingState from "./views/CheckingState";
import ConfirmationState from "./views/ConfirmationState";
import OmittedState from "./views/OmittedState";
import SubmittedState from "./views/SubmittedState";
import ExpiredState from "./views/ExpiredState";
import ErrorState from "./views/ErrorState";

import './App.scss';
import headerLogo from './assets/DellEMCLogo.svg'
import footerLogo from './assets/Druva-Footer-Logo.svg'

function App({appState}) {
  const Context = useContext(AppContext)
  // const [appState, setAppState] = useState(AppContext)

  const AppStateMapping = {
    CHECKING: <CheckingState {...(appState || {})} />,
    OPEN: <OpenState {...(appState || {})} />,
    CONFIRM: <ConfirmationState {...(appState || {})} />,
    OMITTED: <OmittedState {...(appState || {})} />,
    CLOSED: <SubmittedState {...(appState || {})} />,
    EXPIRED: <ExpiredState {...(appState || {})} />,
    ERROR: <ErrorState {...(appState || {})} />,
  }

  const [Content, setContent] = useState(AppStateMapping[appState.type])

  useEffect(() => {
    ;(async () => {
      const {invitationId, action} = Utils.getQueryParamsMap(window.location.search),
          msgInvalidOrder = 'You seem to have clicked on an invalid link or there was an error processing your request'

      if (action) {
        Context.setAppState({type: 'CHECKING', headingText: 'Updating Order Status...'})
      }

      if (invitationId) {
        const results = await Service.checkOrderStatus(invitationId)
        if (results?.orderDetails?.status) {
          if (results.orderDetails.status === 'OPEN' && action === 'OMITTED') {
            const result = await Service.omitInvitation(results.orderDetails)
            if (result?.isSuccess) {
              Context.setAppState({type: 'OMITTED',})
            } else {
              Context.setAppState({type: 'ERROR', invitation: result})
            }
          } else {
            Context.setAppState({
              type: results.orderDetails.status,
              invitation: results.orderDetails,
            })
          }
          return
        }
      }
      Context.setAppState({
        type: 'ERROR',
        errorMessage: msgInvalidOrder,
        invitation: {}
      })
    })()
  }, [])

  useEffect(() => {
    setContent(AppStateMapping[appState.type])
  }, [appState])

  const getCopyrightYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="app-wrap">
      <header>
        <img src="https://dcpcdn.druva.com/logo/dell.svg" alt="Dell EMC Logo" />
        <h1 className="zero-m inline-block">APEX Backup Services</h1>
      </header>
      <section className="content">
        {Content}
      </section>
      <footer className="flex space-between">
        <ul className="horizontal">
          <li>
            Powered by
            <img src={footerLogo} alt="Druva" style={{marginLeft: '5px'}}/>
          </li>
          <li>|</li>
          <li>Privacy Policy</li>
          <li>|</li>
          <li>Support</li>
        </ul>
        <ul className="horizontal">
          <li>&copy; {getCopyrightYear()}  Druva Inc.</li>
        </ul>
      </footer>
    </div>
  );
}

function AppWrapper() {
  const [appState, setAppState] = useState({
    type: 'CHECKING',
    invitation: {}
  })

  return (
    <AppContext.Provider value={{
      appState, setAppState: setAppState
    }}>
      <App appState={appState}/>
    </AppContext.Provider>
  )
}

export default AppWrapper;
