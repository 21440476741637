import React from "react";
import Messaging from "./Messaging";

function OmittedState() {
  return (
      <Messaging
          headingText="Okay, we get that"
          supportLink={<span>
            <a href="https://support.dell.com">Contact us</a>,
            and we will be happy to assist you further with the required information
          </span>}
          subheading={null}
      />
  )
}

export default OmittedState
