import React from "react";
import Messaging from "./Messaging";

function SubmittedState() {
  return (
      <Messaging
          headingText="You have already submitted this information"
          subheading={null}
          supportLink={
            <span>
              For further assistance, contact
              <a href="https://support.dell.com">&nbsp;Support</a>
            </span>
          }
      />
  )
}

export default SubmittedState
