import {Icon, Intent} from "@blueprintjs/core";
import React from "react";
import Messaging from "./Messaging";

function ExpiredState() {
  return (
      <Messaging
          icon={(<Icon icon="heart-broken" intent={Intent.WARNING} />)}
          headingText="This link has expired"
      />
  )
}

export default ExpiredState


